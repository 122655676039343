import { Angles } from "@vertikal/e-prospera.ui.angles";
import Box from "@vertikal/e-prospera.ui.box";
import EPLink from "@vertikal/e-prospera.ui.link";
import { List, ListItem } from "@vertikal/e-prospera.ui.list";
import Text from "@vertikal/e-prospera.ui.text";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Helmet from "react-helmet";
import { Trans, useTranslation } from "react-i18next";
import { Breadcrumbs } from "../components/Breadcrumbs/Breadcrumbs";
import DottedDivider from "../components/DottedDivider/DottedDivider";
import GlobalSettings from "../components/GlobalSettings";
import MainMenu from "../components/MainMenu/MainMenu";
import {
  HowToDoBusiness,
  HowToDoBusinessItem,
  HowToDoBusinessItemImage,
  NumberIcon,
  PartnerItem,
  PartnersGrid,
  WhyDoBusinessInProspera,
} from "../styles/business.styles";
import {
  CTAButton,
  FooterSection,
  TextHighLight,
} from "../styles/index.styles";
import {
  AccentBlock,
  ContentWrapper,
  HeroDottedDividerWrapper,
  RegisterUpsellDividerWrapper,
  ResidencyHeadInfo,
} from "../styles/residency.styles";

const BusinessGetStarted = () => {
  const [t] = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t("public.business.title")}</title>
        <meta name="title" content={t("public.business.meta.title")} />
        <meta
          name="description"
          content={t("public.business.meta.description")}
        />
        <link rel="canonical" href="/business" />
      </Helmet>
      <GlobalSettings />
      <MainMenu darkFooter>
        <AccentBlock paddingTop={1} paddingBottom={3} $color="success">
          <ContentWrapper $wide>
            <Breadcrumbs theme="dark">
              <Link to="/">
                <Trans>common.home</Trans>
              </Link>
              <span>
                <Trans>common.business</Trans>
              </span>
            </Breadcrumbs>
          </ContentWrapper>
          <ContentWrapper>
            <ResidencyHeadInfo>
              <Box>
                <Text variant="h2" as="h1">
                  <Trans>public.business.hero.title</Trans>
                </Text>
              </Box>
              <HeroDottedDividerWrapper>
                <DottedDivider color="success" />
              </HeroDottedDividerWrapper>
              <Box style={{ maxWidth: "32.5rem" }}>
                <Text variant="p-sm">
                  <Trans>public.business.hero.subtitle</Trans>
                </Text>
              </Box>
            </ResidencyHeadInfo>
          </ContentWrapper>
        </AccentBlock>
        <Angles noLight invertColors flipVertical />
        <AccentBlock paddingBottom={6} paddingTop={3.5}>
          <ContentWrapper>
            <WhyDoBusinessInProspera>
              <HowToDoBusinessItemImage>
                <StaticImage
                  width={350}
                  height={256}
                  src="../images/business/why-do-business.svg"
                  alt=""
                />
              </HowToDoBusinessItemImage>
              <Box display="flex" width="100%" flexDirection="column">
                <Box marginBottom={1.5}>
                  <Text variant="h3" as="h2">
                    <Trans>public.business.why_do_business.title</Trans>
                  </Text>
                </Box>
                <Text variant="p-lg">
                  <Trans>public.business.why_do_business.subtitle</Trans>
                </Text>
              </Box>
            </WhyDoBusinessInProspera>
          </ContentWrapper>
        </AccentBlock>
        <Angles flipVertical />
        <AccentBlock $color="accent" paddingTop={4.625} paddingBottom={5}>
          <ContentWrapper>
            <Box
              textAlign="center"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              style={{ color: "#fff", gap: "2rem" }}
              marginBottom={3}
            >
              <Text variant="h3" as="h2">
                <Trans>public.business.how_to_do_business.title</Trans>
              </Text>
              <Text variant="p-lg">
                <Trans>public.business.how_to_do_business.subtitle</Trans>
              </Text>
            </Box>
            <HowToDoBusiness>
              <HowToDoBusinessItem>
                <HowToDoBusinessItemImage>
                  <StaticImage
                    width={260}
                    height={190}
                    placeholder="tracedSVG"
                    src="../images/business/apply-for-residency.svg"
                    layout="fixed"
                    alt=""
                  />
                </HowToDoBusinessItemImage>
                <Box
                  display="flex"
                  flexDirection="column"
                  width="100%"
                  gap="2.25"
                >
                  <Box display="flex" gap="1.5" alignItems="center">
                    <NumberIcon>1</NumberIcon>
                    <Text variant="h4" as="h3">
                      <Trans>
                        public.business.how_to_do_business.apply_for_residency
                      </Trans>
                    </Text>
                  </Box>
                  <Text>
                    <Trans>
                      public.business.how_to_do_business.obtaining_residency_is_quick
                    </Trans>
                  </Text>
                  <Box>
                    <CTAButton
                      color="accent"
                      id="apply-now"
                      target="_blank"
                      href={`${process.env.GATSBY_REDIRECT_URL}/residency`}
                    >
                      <Trans>dashboard.apply_now</Trans>
                    </CTAButton>
                  </Box>
                </Box>
              </HowToDoBusinessItem>
              <DottedDivider direction="horizontal" color="accent" />
              <HowToDoBusinessItem
                display="flex"
                gap="7.5"
                justifyContent="center"
              >
                <HowToDoBusinessItemImage>
                  <StaticImage
                    width={270}
                    height={153}
                    placeholder="tracedSVG"
                    src="../images/business/form-a-business-entity.svg"
                    layout="fixed"
                    alt=""
                  />
                </HowToDoBusinessItemImage>
                <Box
                  width="100%"
                  display="flex"
                  flexDirection="column"
                  gap="2.25"
                >
                  <Box display="flex" gap="1.5" alignItems="center">
                    <NumberIcon>2</NumberIcon>
                    <Text variant="h4" as="h3">
                      <Trans>
                        public.business.how_to_do_business.form_a_business_entity
                      </Trans>
                    </Text>
                  </Box>
                  <Text>
                    <Trans>
                      public.business.how_to_do_business.forming_a_legal_entity
                    </Trans>
                  </Text>
                  <List variant="checklist" collapsed>
                    <ListItem>
                      <Trans>
                        public.business.how_to_do_business.checklist.point_1
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        public.business.how_to_do_business.checklist.point_2
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        public.business.how_to_do_business.checklist.point_3
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        public.business.how_to_do_business.checklist.point_4
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        public.business.how_to_do_business.checklist.point_5
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        public.business.how_to_do_business.checklist.point_6
                      </Trans>
                    </ListItem>
                  </List>
                  <Box>
                    <CTAButton
                      color="main"
                      target="_blank"
                      id="form-a-legal-entity"
                      href={`${process.env.GATSBY_REDIRECT_URL}/entity`}
                    >
                      <Trans>
                        public.business.how_to_do_business.form_a_legal_entity
                      </Trans>
                    </CTAButton>
                  </Box>
                </Box>
              </HowToDoBusinessItem>
            </HowToDoBusiness>
          </ContentWrapper>
        </AccentBlock>
        <Angles flipHorizontal />
        <AccentBlock paddingTop={4} paddingBottom={4}>
          <ContentWrapper>
            <Box display="flex" gap="2" flexDirection="column">
              <Text variant="h3" as="h2">
                <Trans>public.business.how_much_does_it_cost</Trans>
              </Text>
              <Text variant="p-lg">
                <Trans>public.business.total_cost_for_a_resident</Trans>
              </Text>
            </Box>
            <RegisterUpsellDividerWrapper marginTop={6} marginBottom={6}>
              <DottedDivider />
            </RegisterUpsellDividerWrapper>
            <Box marginBottom={3}>
              <Text variant="h3" as="h2">
                <Trans>public.business.partners.title</Trans>
              </Text>
            </Box>
            <PartnersGrid>
              <PartnerItem>
                <Box marginBottom={1.5} textAlign="center">
                  <StaticImage
                    width={190}
                    height={96}
                    alt=""
                    src="../images/business/zaha-hadid.png"
                  />
                </Box>
                <Text variant="p-sm">
                  <Trans>public.business.partners.partner_1.title</Trans>
                </Text>
                <Text color="gray" variant="p-xs">
                  <Trans>public.business.partners.partner_1.subtitle</Trans>
                </Text>
              </PartnerItem>
              <PartnerItem>
                <Box marginBottom={1.5} textAlign="center">
                  <StaticImage
                    width={190}
                    height={96}
                    alt=""
                    src="../images/business/ey-logo.png"
                  />
                </Box>
                <Text variant="p-sm">
                  <Trans>public.business.partners.partner_2.title</Trans>
                </Text>
                <Text color="gray" variant="p-xs">
                  <Trans>public.business.partners.partner_2.subtitle</Trans>
                </Text>
              </PartnerItem>
              <PartnerItem>
                <Box marginBottom={1.5} textAlign="center">
                  <StaticImage
                    width={190}
                    height={96}
                    alt=""
                    src="../images/business/island-concrete-logo.png"
                  />
                </Box>
                <Text variant="p-sm">
                  <Trans>public.business.partners.partner_3.title</Trans>
                </Text>
                <Text color="gray" variant="p-xs">
                  <Trans>public.business.partners.partner_3.subtitle</Trans>
                </Text>
              </PartnerItem>
              <PartnerItem>
                <Box marginBottom={1.5} textAlign="center">
                  <StaticImage
                    width={190}
                    height={96}
                    alt=""
                    src="../images/business/hs-urbanismo-logo.png"
                  />
                </Box>
                <Text variant="p-sm">
                  <Trans>public.business.partners.partner_4.title</Trans>
                </Text>
                <Text color="gray" variant="p-xs">
                  <Trans>public.business.partners.partner_4.subtitle</Trans>
                </Text>
              </PartnerItem>
              <PartnerItem>
                <Box marginBottom={1.5} textAlign="center">
                  <StaticImage
                    width={190}
                    height={96}
                    alt=""
                    src="../images/business/aca-logo.png"
                  />
                </Box>
                <Text variant="p-sm">
                  <Trans>public.business.partners.partner_5.title</Trans>
                </Text>
                <Text color="gray" variant="p-xs">
                  <Trans>public.business.partners.partner_5.subtitle</Trans>
                </Text>
              </PartnerItem>
              <PartnerItem>
                <Box marginBottom={1.5} textAlign="center">
                  <StaticImage
                    width={190}
                    height={96}
                    alt=""
                    src="../images/business/elite-logo.png"
                  />
                </Box>
                <Text variant="p-sm">
                  <Trans>public.business.partners.partner_6.title</Trans>
                </Text>
                <Text color="gray" variant="p-xs">
                  <Trans>public.business.partners.partner_6.subtitle</Trans>
                </Text>
              </PartnerItem>
            </PartnersGrid>
            <Box marginTop={4}>
              <Text>
                <Trans
                  components={{
                    a: (
                      <EPLink
                        id="business-register-search"
                        href="/business/search"
                      />
                    ),
                  }}
                >
                  public.about.partners.find_entities
                </Trans>
              </Text>
            </Box>
          </ContentWrapper>
        </AccentBlock>
        <Angles flipVertical flipHorizontal />
        <FooterSection>
          <Box textAlign="center">
            <Box marginBottom={2.5}>
              <Text variant="h2">
                <Trans components={{ highlight: <TextHighLight /> }}>
                  public.homepage.footer.title
                </Trans>
              </Text>
            </Box>
            <CTAButton
              id="join_now"
              color="main"
              href={process.env.GATSBY_REDIRECT_URL}
            >
              <Trans>common.join_now</Trans>
            </CTAButton>
          </Box>
        </FooterSection>
      </MainMenu>
    </>
  );
};

export default BusinessGetStarted;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
